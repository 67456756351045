import { Inject, Injectable, signal } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  BrowserAuthError,
  InteractionRequiredAuthError,
  RedirectRequest
} from '@azure/msal-browser';
import { EMPTY, exhaustMap, Observable, of, Subject, switchMap, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class OAuthSSOAzureService {
  private authenticateCompleteSubject = new Subject<AuthenticationResult>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private azureAuthService: MsalService
  ) {
  }

  public authenticateCompleteEvent$(): Observable<AuthenticationResult> {
    return this.authenticateCompleteSubject.pipe(
      tap(() => this.checkAndSetActiveAccount())
    );
  }

  // Subscribing to handleRedirectObservable before any other functions both initializes the application and ensures redirects are handled
  public handleRedirectObservableAction$(): Observable<AuthenticationResult> {
    return this.azureAuthService.handleRedirectObservable().pipe(
      exhaustMap((authResult: AuthenticationResult) => {
        console.log('AuthenticationResult', authResult);
        if (authResult) {
          this.authenticateCompleteSubject.next(authResult);
          return of(authResult);
        }

        return this.azureAuthService.ssoSilent({
          loginHint: this.azureAuthService.instance.getActiveAccount()?.username
        }).pipe(
          tap(authenticationResult => {
            console.log('[[ SsoSilent Succeeded ]]', authenticationResult);
            this.authenticateCompleteSubject.next(authenticationResult);
          }),
          catchError(err => {
            console.log('[[ SsoSilent Failed ]]', err, this.msalGuardConfig.authRequest);
            const redirectRequest = {
              ...this.msalGuardConfig.authRequest
            } as RedirectRequest;
            if (err instanceof InteractionRequiredAuthError) {
              return this.azureAuthService.acquireTokenRedirect(redirectRequest).pipe(
                switchMap(() => EMPTY),
                catchError(err => throwError(() => err))
              );
            }

            if (err instanceof BrowserAuthError) {
              // todo 인증서버 계속 접근 시 오류 해결 필요
              // https://app.asana.com/0/692598029547572/1207617213102080
              alert('인증서버 계속 접근 시 오류 해결 필요');
              return throwError(() => err);
            }

            return this.azureAuthService.loginRedirect(redirectRequest).pipe(
              switchMap(() => throwError(() => err))
            );
          })
        );
      })
    );
  }

  public checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.azureAuthService.instance.getActiveAccount();

    if (!activeAccount && this.azureAuthService.instance.getAllAccounts().length > 0) {
      const accounts = this.azureAuthService.instance.getAllAccounts();
      this.azureAuthService.instance.setActiveAccount(accounts[0]);
    }
  }
}
